<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Total
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ totalCategories }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Active
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ activeCategories }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Disabled
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ inactiveCategories }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <categories-table></categories-table>
  </div>
</template>

<script>
import categoriesTable from "@/components/suppliers/categoriesTable.vue";
import Helpers from "@/utils/helpers";
export default {
  name: "CategoriesPage",
  components: {
    categoriesTable,
  },
  layout: "authenticated",
  data() {
    return {
      helpers: new Helpers(),
      totalCategories: 0,
      activeCategories: 0,
      inactiveCategories: 0,
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped></style>
