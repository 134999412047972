<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat class="primary--text text-h6">
            {{ tableTitle }}
          </v-toolbar>
          <div class="px-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </div>
          <v-data-table
            class="row-pointer"
            :search="search"
            :headers="userHeader"
            :items="categories"
            :items-per-page="10"
            :loading="loadCategories"
          >
            <template v-slot:item.numbers="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.disabled="{ item }">
              {{ item.disabled === "false" ? "Enabled" : "Disabled" }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon>
                <v-icon
                  v-if="item.disabled === 'false'"
                  color="error"
                  title="Disable"
                  @click="enableDisableCategory(item, 'disable')"
                >
                  mdi-eye-off-outline
                </v-icon>
                <v-icon
                  v-else
                  color="success"
                  title="Enable"
                  @click="enableDisableCategory(item, 'enable')"
                >
                  mdi-eye-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <response-message
      v-if="showResponse"
      :response-message="responseMessage"
      :response-type="responseType"
      :show-response="showResponse"
    ></response-message>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Helpers from "../../utils/helpers";

export default {
  name: "DashboardPage",
  layout: "authenticated",
  components: {},
  data() {
    return {
      loadCategories: false,
      helpers: new Helpers(),
      userHeader: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Name", value: "title" },
        { text: "Category", value: "category" },
        { text: "Sub Category", value: "subcategory_id" },
        { text: "Status", value: "disabled" },
        { text: "Actions", value: "actions" },
      ],
      categories: [],
      placeholder: "search... name, email, phone, account number",
      showResponse: false,
      responseMessage: "",
      responseType: "",
      search: "",
    };
  },
  computed: {
    tableTitle() {
      return this.$route.path.includes("categories")
        ? "All Categories"
        : "Recent Registrations";
    },
  },
  methods: {
    ...mapActions({
      get_categories: "suppliers/get_categories",
      enable_disable_category: "suppliers/enable_disable_category",
    }),
    async getCategories() {
      this.loadCategories = true;
      try {
        const response = await this.get_categories(this.$route.params.id);
        this.categories = response.data.map((row) => {
          row.disabled = String(row.disabled);
          return row;
        });
        const categories = this.categories;
        this.$parent.activeCategories = categories.filter(
          (x) => x.disabled === "false"
        ).length;
        this.$parent.inactiveCategories = categories.filter(
          (x) => x.disabled === "true"
        ).length;
        this.$parent.totalCategories = categories.length;
        this.loadCategories = false;
      } catch (e) {
        this.loadCategories = false;
      }
    },
    async enableDisableCategory(item, action) {
      this.loadCategories = true;
      try {
        const data = {
          disabled: action === "disable",
          subcategory_id: item.subcategory_id,
        };
        await this.enable_disable_category(data);
        await this.getCategories();
        this.loadCategories = false;
      } catch (e) {
        this.loadCategories = false;
        this.responseType = "error";
        this.responseMessage = e?.response?.data?.message;
        this.showResponse = true;
      }
    },
  },
  created() {
    this.getCategories();
  },
};
</script>
